<template>
    <secondary-nav-bar
        class="secondary-nav-section-module"
        :menu-items="menuItems"
    />
</template>

<script>
    import SecondaryNavBar from '../../../molecules/SecondaryNavBar/SecondaryNavBar';

    export default {
        name: 'secondary-nav-section-module',

        components: { SecondaryNavBar },

        props: {
            attributes: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            /**
             * Format menu items for secondary navbar.
             *
             * @returns {Array}
             */
            menuItems() {
                return [1, 2, 3, 4].map((num) => {
                    return {
                        name: this.attributes[`nav_button_text_${ num }`],
                        to: this.attributes[`nav_button_link_${ num }`]?.data
                            ?.attributes?.uri,
                    };
                }).filter(({ name, to }) => name && to);
            },
        },
    };
</script>
